
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import FeedWidget from "@/view/content/widgets/feeds/CardBaseService.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageTables } from "@/graphql/queries";

interface ItemFeatureCollection {
  id: string;
}
interface FeatureCollection {
  id: string;
  href: string;
  title: string;
  subtitle: string;
  items: Array<ItemFeatureCollection>;
}

interface PageBreadcrumbs {
  id: string;
}

interface Breadcrumbs {
  pages: Array<PageBreadcrumbs>;
  heading: string;
  section: string;
  title: string;
}

interface Metadata {
  featureCollection: Array<FeatureCollection>;
  breadcrumbs: Breadcrumbs;
}

export default defineComponent({
  name: "ecommerce",
  components: {
    HeaderPage,
    FeedWidget,
  },
  setup() {
    const breadcrumbs = {
      heading: "Profile",
      title: "",
      section: "",
      pages: [
        {
          id: 1,
          icon: "bi bi-calendar-check",
          pulse: true,
          pulseColor: "",
          heading: "Overview",
          route: "/overview",
        },
        {
          id: 2,
          icon: "bi bi-droplet-half text-primary",
          pulse: true,
          pulseColor: "",
          heading: "Account",
          route: "/account",
        },
        {
          id: 3,
          icon: "bi bi-droplet-half text-primary",
          pulse: false,
          pulseColor: "",
          heading: "Settings",
          route: "/settings",
        },
        {
          id: 4,
          icon: "bi bi-droplet-half text-primary",
          pulse: false,
          pulseColor: "",
          heading: "Settings",
          route: "/settings",
        },
        {
          id: 5,
          icon: "bi bi-droplet-half text-primary",
          pulse: true,
          pulseColor: "",
          heading: "Settings",
          route: "/settings",
        },
      ],
    };

    return {
      breadcrumbs,
    };
  },
  mounted() {
    const route = useRoute();
    const params = route.params.company_name;
    this.loadPage(params).then(() => {
      setCurrentPageBreadcrumbs("Marketplace", this.ecommerce.pageBreadcrumbs);
    });
  },
  data() {
    return {
      ecommerce: {
        pageBreadcrumbs: "",
        banner: "",
        bannerSmall: "",
        metadata: {} as Metadata,
      },
      items: [],
    };
  },
  methods: {
    async loadPage(pageId) {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageTables, {
            filter: {
              pageId: {
                eq: pageId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageTables.items.length > 0) {
          let page = response.data.listPageTables.items[0];
          if (page) {
            this.ecommerce = page;
            this.ecommerce.metadata = JSON.parse(page.metadata);
            const banner = await Storage.get(this.ecommerce.banner, {
              level: "public",
            });
            this.ecommerce.banner = banner;

            const bannerSmall = await Storage.get(this.ecommerce.bannerSmall, {
              level: "public",
            });
            this.ecommerce.bannerSmall = bannerSmall;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
