import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card card-stretch gutter-b" }
const _hoisted_2 = { class: "card-body px-0" }
const _hoisted_3 = {
  key: 0,
  id: "ecommerce-collection-wrapper",
  class: "row justify-content-center align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_page = _resolveComponent("header-page")!
  const _component_feed_widget = _resolveComponent("feed-widget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_page, {
      title: _ctx.ecommerce.title,
      subtitle: _ctx.ecommerce.shortDescription,
      description: _ctx.ecommerce.description,
      "bg-image": _ctx.ecommerce.banner,
      "bg-image-small": _ctx.ecommerce.bannerSmall,
      search: _ctx.ecommerce.search,
      lg: false,
      bgHex: "#fff",
      margin: "mb-xxl-6"
    }, null, 8, ["title", "subtitle", "description", "bg-image", "bg-image-small", "search"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.ecommerce.metadata.featureCollection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ecommerce.metadata.featureCollection, (category) => {
              return (_openBlock(), _createElementBlock("div", {
                key: category.id,
                class: "col-sm-12 col-lg-6 me-n1 my-4"
              }, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_feed_widget, {
                      title: category.title,
                      subtitle: category.subtitle,
                      bio: category.shortDescription,
                      "banner-small": true,
                      image: category.metadata.banner,
                      url: category.href
                    }, null, 8, ["title", "subtitle", "bio", "image", "url"])
                  ]),
                  _: 2
                }, 1024))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}